<template>
  <transition name="fadein">
    <div class="c-header">
      <div class="c-header__inner">
        <a href="/" class="c-header__logo"></a>
        <div class="c-header__content">
          <h1>{{ headerInfo.title }}</h1>
          <span class="subtitle">{{ headerInfo.subtitle }}</span>
        </div>
        <div class="c-header__progress" v-bind:style="{ width: progressWidth + '%' }"></div>
        <a href="#" v-if="isStart" @click.prevent="$store.commit('showShareModal', sheetData)" class="c-header__sharebtn"><span class="hidden">Share</span></a>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'c-header',
  props: [],
  data () {
    return {

    }
  },
  mounted() {
  },
  methods: {
  },
  computed: {
    headerInfo() {
      return this.$store.getters.headerInfo;
    },
    progressWidth() {
      return this.$store.getters.sheetProgress;
    },
    isStart() {
      return this.$route.name == 'start';
    },
    sheetData() {
      return this.$store.state.sheetData;
    }
  }
}
</script>

<style lang="scss">

  @import '../styles/helpers';

  .c-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    font-size: .8rem;
    height: 4.5rem;
    z-index: 3;

    &__inner {
      position: relative;
      border-top: 4px solid $c-red;
      border-bottom: 1px solid #eee;
      padding: 0 1.5rem;
      box-sizing: border-box;
      background-color: #fff;
      display: flex;
      width: 100%;
      height: 100%;
      max-width: 850px;
      margin: 0 auto;
      align-items: center;
    }

    &__progress {
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 50%;
      height: 1px;
      background-color: #aaa;
      transition: .5s width $easeOutQuint;
    }

    &__logo {
      display: block;
      width: 2rem;
      height: 2rem;
      margin-right: .75rem;
      background-image: url('../assets/header-logo.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;

      @include retina() {
        background-image: url('../assets/header-logo@2x.png');
      }
    }
    &__sharebtn {
      width: calc(3rem - 5px);
      height: calc(3rem - 5px);
      background-image: url('../assets/icon-share.svg');
      background-size: 55%;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      top: .75rem;
      right: 1.5rem;
      background-color: #fafafa;
      border: 1px solid #eee;
      // box-shadow: 0 2px 13px -4px rgba(#000, .15);
      border-radius: 99%;
      box-sizing: border-box;
    }
    h1 {
      margin: 0;
      font-weight: 700;
      font-size: inherit;
    }
    .subtitle {
      color: #888;
    }
  }

  .fadein-enter-active, .fadein-leave-active {
    transition: .5s opacity;
  }
  .fadein-enter, .fadein-leave-to {
    opacity: 0;
  }

</style>
