<template>
  <div class="c-wordstaskscore">
    <strong>{{ taskid + 1 }}.</strong>
    <div class="" ref="text" v-html="parsedText"></div>
  </div>
</template>

<script>
export default {
  name: "c-wordstaskscore",
  props: ["task", "taskid"],
  data() {
    return {};
  },
  watch: {},
  mounted() {
    // console.log(this.answers);
  },
  methods: {},
  computed: {
    sheetId() {
      return this.$store.getters.sheetId;
    },
    sheetData() {
      return this.$store.getters.sheetData;
    },
    answers() {
      return this.$store.getters.answers;
    },
    parsedText() {
      let c = -1;
      let txt = this.task.taskable.text.replaceAll(
        /(?:\[([a-zA-Z\x7f-\xff'-]+)\])*([a-zA-Z\x7f-\xff'-]+)*/g,
        (match, str1, str2) => {
          if (str1 !== undefined || str2 !== undefined) {
            c += 1;
          } else {
            return "";
          }
          let answ = null;
          if (this.answers[this.taskid + 1 + "w" + c]) {
            answ = this.answers[this.taskid + 1 + "w" + c];
          }
          if (str1 !== undefined) {
            let cl = "word cor";
            if (answ && answ.correct) {
              cl += " selected";
            }
            return `<span class="${cl}">${str1}</span>`;
          } else if (str2 !== undefined) {
            if (!answ) return str2;
            return `<span class="word selected">${str2}</span>`;
          } else {
            return "";
          }
        }
      );
      txt += "&nbsp;";
      return txt;
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/helpers";

.c-wordstaskscore {
  margin-bottom: 0.5rem;
  border: 1px solid #eee;
  background-color: #fafafa;
  border-radius: 4px;
  padding: 0.5rem;

  .text {
    display: block;
    margin-bottom: 0.25rem;
  }

  .word {
    &.cor {
      color: green;
      font-weight: bold;
      border-radius: 4px;
    }
    &.selected {
      border-radius: 4px;
      padding: 0 4px;
      background-color:#FFE4E4;
      border: 2px solid #f3c9c9;

      &.cor {
        border: 2px solid #30ca91;
        background-color: #B3FDE2;
      }
    }
  }
}
</style>
