import Vue from 'vue'
import Router from 'vue-router'
import Index from '@/components/Index'
import Start from '@/components/Start'
import Media from '@/components/Media'
import Task from '@/components/tasks/Task'
import Score from '@/components/Score'
import SearchView from '@/components/SearchView'
import NotFound from '@/components/NotFound'

Vue.use(Router)

export default new Router({
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'index',
      component: Index
    },
    {
      path: '/sheets/:sheetId',
      name: 'start',
      component: Start
    },
    {
      path: '/search',
      name: 'search',
      component: SearchView
    },
    {
      path: '/sheets/:sheetId/media/:mediaId',
      name: 'media',
      component: Media
    },
    {
      path: '/sheets/:sheetId/tasks/:taskId',
      name: 'task',
      component: Task
    },
    {
      path: '/sheets/:sheetId/score',
      name: 'score',
      component: Score
    },
    //{ path: '*', component: Index },
    {
      path: '/index.php/launchMulcho/intro/:id',
      redirect: to => {
        const { hash, params, query } = to;
        return '/sheets/m:id';
      }
    },
    {
      path: '/index.php/launchMulcho/restart/:id',
      redirect: to => {
        const { hash, params, query } = to;
        return '/sheets/m:id';
      }
    },
    {
      path: '/index.php/launch/mulcho/:id',
      redirect: to => {
        const { hash, params, query } = to;
        return '/sheets/m:id';
      }
    },
    {
      path: '/index.php/launch/gaps/:id',
      redirect: to => {
        const { hash, params, query } = to;
        return '/sheets/g:id';
      }
    },
    {
      path: '/sheet/:slug',
      redirect: to => {
        const { hash, params, query } = to;
        return '/sheets/:slug';
      }
    },
    {
      path: '*',
      component: NotFound
    }
  ]
})
