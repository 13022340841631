import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueMeta from 'vue-meta';

Vue.use(Vuex)

function shuffleArray(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
}

function fancyTimeFormat(time)
{
  var hrs = ~~(time / 3600);
  var mins = ~~((time % 3600) / 60);
  var secs = time % 60;

  var ret = "";

  if (hrs > 0) {
    ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
  }

  ret += "" + mins + ":" + (secs < 10 ? "0" : "");
  ret += "" + secs;
  return ret;
}

const state = {
  routerTransition: 'slide-left',
  pending: false,
  errorMsg: null,
  sheetId: null,
  sheetData: null,
  mainMedia: null,
  answers: {},
  headerInfo: {
    title: '',
    subtitle: ''
  },
  timerNow: 0,
  timerMax: 0,
  timeOut: false,
  sheetProgress: 0,
  activeTask: 0,
  showShareModal: false,
  shareSheet: null,
  categories: []
}

const mutations = {
  setSheetId(state, id) {
    state.sheetId = id;
  },
  setSheetData(state, data) {
    state.sheetData = data;

    if(!data) return;
    if(data.main_media) {
      this.commit('setMainMedia', data.main_media);
    }
  },
  setMainMedia(state, id) {
    state.mainMedia = state.sheetData.media.find((el) => {
      return el.id == id;
    });
    state.sheetData.media = state.sheetData.media.filter((el) => {
      return el.id != id;
    });
  },
  loadStart(state) {
    state.pending = true;
  },
  loadFinish(state) {
    state.pending = false;
  },
  error(state, msg) {
    state.errorMsg = msg;

    window.setTimeout(() => {
      state.errorMsg = '';
    }, 2000);
  },
  addAnswer(state, answer) {
    state.answers[answer.id] = {
      content: answer.content,
      correct: answer.correct
    }

    this.dispatch('saveLocalData');
  },
  clearAnswers(state) {
    state.answers = {};
    state.activeTask = null;

    this.dispatch('saveLocalData');
  },
  setAnswers(state, answers) {
    state.answers = answers;
  },
  setHeader(state, info) {
    state.headerInfo = {
      title: info.title,
      subtitle: info.subtitle
    }
  },
  setTimerNow(state, s) {
    state.timerNow = s;
  },
  setTimerMax(state, s) {
    state.timerMax = s;
  },
  timeout(state) {
    state.timeOut = true;
  },
  increaseTimerNow(state) {
    state.timerNow++;
  },
  setRouterTransition(state, transition) {
    state.routerTransition = transition;
  },
  setTasks(state, tasks) {
    state.sheetData.tasks = tasks;
  },
  setSheetProgress(state, perc) {
    state.sheetProgress = perc;
  },
  setActiveTask(state, id) {
    state.activeTask = id;
  },
  showShareModal(state, sheet) {
    state.shareSheet = sheet;
    state.showShareModal = true;
  },
  hideShareModal(state) {
    state.showShareModal = false;
  },
  setCategories(state, data) {
    data.forEach(d => {
      Vue.set(d, 'active', false);
    });
    
    state.categories = data;
  },
  toggleCategory(state, index) {
    state.categories[index].active = !state.categories[index].active;
  }
}

const actions = {
  saveLocalData(context) {
    let a = JSON.stringify(context.getters.answers);
    localStorage.setItem('answers', a);
  },
  getSheetData(context, data) {
    context.commit('loadStart');
    context.commit('setSheetId', data.sheetId);

    /*let apiUrl = 'https://backend.onlineuebungen.schularena.com';

    if(process.env.NODE_ENV == 'development') {
      apiUrl = 'http://sc04-backend.test';
    }*/

    let apiUrl = process.env.VUE_APP_APIURL;

    return new Promise((resolve, reject) => {
      axios.get(apiUrl + 'api/tasksheets/' + data.sheetId)
        .then(response => {
          context.commit('loadFinish');

          if(response.data.length < 1) {
            context.commit('error', 'Keine Übung gefunden');
            context.commit('setSheetData', null);
            reject();
          } else if (response.data.url) {
            context.commit('error', 'Bitte warten...');
            context.commit('loadFinish');
            window.location.replace(response.data.url);
            reject();
          } 

          context.commit('setSheetData', response.data);
          resolve();
        })
        .catch(response => {
          // let msg = response.data.message || 'Error';
          let msg = 'Fehler: Übung konnte nicht geladen werden';
          context.commit('loadFinish');
          context.commit('error', msg);
          context.commit('setSheetData', null);

          reject();
        });
    });
  },
  getCategories(context) {
    let apiUrl = process.env.VUE_APP_APIURL;
    
    axios.get(apiUrl + 'api/categories')
      .then(response => {
        context.commit('setCategories', response.data);
      })
      .catch(response => {
        console.log(response);
      });
  },
  randomizeTasks(context) {
    let tasks = state.sheetData.tasks;
    shuffleArray(tasks);
    context.commit('setTasks', tasks);
  },
  initTimer(context) {
    if(window.timer) window.clearTimeout(window.timer);
    context.commit('setTimerNow', 0);
    context.commit('setTimerMax', context.getters.sheetData.time_limit);
  },
  startTimer(context) {
    if(context.getters.timerMax == 0) return;
    context.dispatch('increaseTimer');
  },
  stopTimer(context) {
    window.clearTimeout(window.timer);
  },
  increaseTimer(context) {
    context.commit('increaseTimerNow');

    if(context.getters.timerNow >= context.getters.timerMax) {
      context.commit('timeout');
      return;
    }
    window.timer = setTimeout(() => {
      context.dispatch('increaseTimer');
    }, 1000);
  }
}

const getters = {
  routerTransition: state => {
    return state.routerTransition;
  },
  sheetId: state => {
    return state.sheetId;
  },
  sheetData: state => {
    return state.sheetData;
  },
  pending: state => {
    return state.pending;
  },
  errorMsg: state => {
    return state.errorMsg;
  },
  answers: state => {
    return state.answers;
  },
  headerInfo: state => {
    return state.headerInfo;
  },
  timerNow: state => {
    return state.timerNow;
  },
  timerMax: state => {
    return state.timerMax;
  },
  timeOut: state => {
    return state.timeOut;
  },
  formattedTimerNow: state => {
    let remain = state.timerMax - state.timerNow;
    let time = fancyTimeFormat(remain);

    return time;
  },
  mainMedia: state => {
    return state.mainMedia;
  },
  sheetProgress: state => {
    return state.sheetProgress;
  },
  activeTask: state => {
    return state.activeTask;
  },
  activeCategoriesString: state => {
    if(!state.categories || state.categories.length == 0) return [];
    let cats = state.categories.reduce((acc, cat) => {
      if(cat.active) {
        acc.push(cat.id);
      }
      return acc;
    }, []);
    if(cats) return cats.toString();
    return [];
  }
}

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations
})
