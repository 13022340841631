<template>
  <div class="c-gapstaskscore">
    <strong>{{ taskid+1 }}.</strong>
    <div class="" ref="text"></div>
  </div>
</template>

<script>
export default {
  name: 'c-gapstaskscore',
  props: ['task', 'taskid'],
  data () {
    return {
    }
  },
  watch: {

  },
  mounted() {
    this.decodeText();
  },
  methods: {
    decodeText() {
      let formattedText = this.task.taskable.text.replace(/(\[)([^}]*?)(\])/g, '<span class="gap gap--'+ this.task.id + '"><span class="correct">$2</span></span>');
      this.$refs.text.innerHTML = formattedText;

      this.insertUserAnswers();
      this.formatCorrectAnswers();
    },
    insertUserAnswers() {
      let gaps = document.querySelectorAll('.gap--' + this.task.id);
      this.gapsCount = gaps.length;
      for (let i = 0; i < gaps.length; ++i) {
        let answer = this.answers[this.task.taskable.id + 'g' + i];
        if(answer) {
          let isCorrect = answer.correct;

          if(!isCorrect) {
            let node = document.createElement('span');
            node.classList.add('false');
            node.textContent = answer.content;
            gaps[i].prepend(node);
          }
        }else{
          let node = document.createElement('span');
          node.classList.add('empty');
          node.textContent = '—';
          gaps[i].prepend(node);
        }
      }
    },
    formatCorrectAnswers() {
      let gaps = document.querySelectorAll('.gap--' + this.task.id + ' .correct');
      for (let i = 0; i < gaps.length; ++i) {
        let answers = gaps[i].textContent;
        let answer = answers.split(';')[0];
        gaps[i].textContent = answer;
      }
    }
  },
  computed: {
    sheetId() {
      return this.$store.getters.sheetId;
    },
    sheetData() {
      return this.$store.getters.sheetData;
    },
    answers() {
      return this.$store.getters.answers;
    },
    formattedText() {
      return this.task.taskable.text.replace(/(\[)([^}]*?)(\])/g, '<span class="gap">$2</span>');
    }
  }
}
</script>

<style lang="scss">

  @import '../../styles/helpers';

  .c-gapstaskscore {
    margin-bottom: .5rem;
    border: 1px solid #eee;
    background-color: #fafafa;
    border-radius: 4px;
    padding: .5rem;

    .text {
      display: block;
      margin-bottom: .25rem;
    }

    .gap {
      display: inline-block;
      background-color: #fff;
      padding: 5px;
      border: 1px solid #eee;

      .correct {
        padding: 0 3px;
        background-color: #B3FDE2;
      }
      .false {
        padding: 0 3px;
        background-color: #FFE4E4;
        margin-right: 5px;
      }
      .empty {
        padding: 0 3px;
        background-color: #DDD;
        margin-right: 5px;
      }
    }

  }

</style>
