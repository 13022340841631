<template>
  <div class="c-mulchotask">
    <div class="c-mulchotask__question" v-html="compiledQuestion"></div>
    <div v-if="description" class="c-task__description" v-html="description"></div>
    <div class="c-mulchotask__answers">
      <ul>
        <li
          v-for="item in answers"
          @click="toggleAnswer(item)"
          v-bind:key="item.id"
          v-bind:class="{ active: item.checked }">
          {{ item.answer }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import marked from 'marked';

export default {
  name: 'mulchotask',
  props: ['task', 'description', 'parent'],
  data () {
    return {
      answers: [],
      correct: false
    }
  },
  mounted() {
    this.answers = this.task.answers;

    if(this.storeAnswers) {
      this.answers.forEach((a, i) => {
        this.$set(a, 'checked', this.storeAnswers[i].checked);
      });
    }else{
      this.answers.forEach((a) => {
        this.$set(a, 'checked', false);
      });
    }
    this.shuffleArray(this.answers);

    this.checkAnswers();

  },
  watch: {
  },
  methods: {
    shuffleArray(a) {
      for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
      }
    },
    checkAnswers() {
      let c = 1;

      this.answers.forEach((a => {
        if(a.correct && !a.checked) c = false;
        if(!a.correct && a.checked) c = false;
      }));

      if(c) this.correct = 1;
      // this.correct = c;

      this.$store.commit('addAnswer', {
        id: this.taskId,
        content: this.answers,
        correct: this.correct
      });
    },
    toggleAnswer(item) {
      item.checked = !item.checked;

      this.checkAnswers();
    }
  },
  computed: {
    sheetData() {
      return this.$store.getters.sheetData;
    },
    sheetId() {
      return this.$route.params.sheetId;
    },
    taskId() {
      if(!this.parent) return;
      return this.parent.id;
      return this.$route.params.taskId;
    },
    compiledQuestion() {
      return marked(this.task.question);
    },
    storeAnswers() {
      if(!this.$store.getters.answers[this.taskId]) return null;
      return this.$store.getters.answers[this.taskId].content;
    }
  }
}
</script>

<style lang="scss">

  @import '../../styles/helpers';

  .c-mulchotask {

    &__question {
      font-weight: bold;
      margin-bottom: 1rem;
      font-size: 1.25rem;

      @include desktop() {
        font-size: 1.5rem;
      }
    }
    &__answers {
      margin-top: 2rem;

      ul {

        li {
          padding: 1rem;
          border: 2px solid #e0e0e0;
          background-color: #f6f6f6;
          color: #666;
          margin-bottom: .5rem;
          cursor: pointer;

          &:hover {
            background-color: #eee;
            border-color: #ddd;
          }
          &.active {
            border-color: #000;
            color: #000;
            box-shadow: 0 5px 8px 0 rgba(#000, .1);
          }
        }
      }
    }

  }

</style>
