<template>
  <div class="c-index">
    <div class="c-index__content">
      <div class="c-index__logo">
        <img src="/images/sc-logo@2x.png">
      </div>
      <Searchbar />
      <div class="c-index__list">
        <CategoryFilter />
        <h1>Unsere Übungen <img src="/images/ajax-loader.gif" alt="Loading" v-if="loadingSheets"></h1>
        <SheetPreviewList v-if="sheets" :sheets="sheets" :ad="ad" />
      </div>
      <div class="c-index__footer">
        <span>© 2004 - {{ currentYear }} SchulArena.com GmbH</span>
        <ul>
          <li><a target="_blank" href="https://www.schularena.com">Hauptseite</a></li>
          <li><a target="_blank" href="https://arbeitsblaetter.schularena.com">Arbeitsblätter-Abo</a></li>
          <li><a target="_blank" href="https://shop.schularena.com">Shop</a></li>
          <li><a target="_blank" href="https://www.schularena.com/support">Support</a></li>
          <li><a target="_blank" href="https://www.schularena.com/kontakt">Kontakt</a></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import SheetPreviewList from './SheetPreviewList';
import Searchbar from './Searchbar';
import CategoryFilter from './CategoryFilter';

export default {
  name: 'index',
  metaInfo() {
    return {
      title: 'Schularena.com Onlineübungen',
      titleTemplate: null,
      meta: [
        { 'property': 'og:image', 'content': 'https://onlineuebungen.schularena.com/meta/og-image.jpg', 'vmid': 'og:description'}
      ]
    }
  },
  data () {
    return {
      sheetId: null,
      apiUrl: process.env.VUE_APP_APIURL,
      sheets: [],
      loadingSheets: false,
      ad: {
        title: 'Welt von SchulArena',
        text: 'Entdecke die Welt von SchulArena mit vielen kostenlosen Onlineübungen für Schülerinnen und Schüler sowie tollen Unterrichtsmaterialien (kostenpflichtig) für Lehrerinnen und Lehrer.',
        link: 'https://www.schularena.com/',
        image: '/images/mitmachen.jpg'
      }
    }
  },
  components: {
    SheetPreviewList,
    Searchbar,
    CategoryFilter
  },
  mounted() {
    this.$store.commit('setHeader', {
      title: 'Schularena.com',
      subtitle: 'Onlineübungen'
    });
    this.loadData();
  },
  watch: {
    activeCategoriesString(to, from) {
      this.loadData();
    }
  },
  computed: {
    currentYear() {
      return (new Date()).getFullYear();
    },
    activeCategoriesString() {
      return this.$store.getters.activeCategoriesString;
    }
  },
  methods: {
    loadData() {
      this.sheets = [];
      this.loadingSheets = true;
      axios.get(this.apiUrl + 'api/tasksheets' + '?cats=' + this.activeCategoriesString)
        .then(data => {
          this.sheets = data.data.data;
          this.loadingSheets = false;
        })
        .catch(error => {
          this.loadingSheets = false;
          console.log(error);
        });
    },
    startSheet() {
      if(!this.sheetId) return;
      this.$store.commit('setRouterTransition', 'slide-left');
      this.$router.push({ name: 'start', params: { sheetId: this.sheetId } });
    }
  }
}
</script>

<style lang="scss">

  @import '../styles/helpers';

  .c-index {
    margin-top: -3rem;
    margin-bottom: -4rem;

    h1 {
      text-align: center;
    }
    &__logo {
      width: 100%;
      max-width: 400px;
      margin: 0 auto;

      img {
        width: 100%;
        height: auto;
      }
    }
    label {
      margin-top: 2rem;
      margin-bottom: .25rem;
      display: block;
      color: #888;
      text-align: center;
    }
    &__form {
      margin: 0 auto;
      max-width: 500px;
    }
    &__input {
      display: block;
      width: 100%;
      font-family: $f-body;
      font-size: 1.25rem;
      padding: 0 .75rem;
      line-height: 3rem;
      box-sizing: border-box;
      margin-left: auto;
      margin-right: auto;
      background-color: #fff;
      border-radius: 1.5rem;
      box-shadow: 0 1px 4px 0 rgba(#000, .1);
      border: 1px solid #333;
      text-align: center;
      -webkit-appearance: none;
      transition: .2s border-color;

      &:focus {
        outline: none;
        border-color: #aaa;
      }
      &::placeholder {
        color: #ccc;
      }
    }
    &__button {
      display: block;
      margin: 1rem auto;
      font-family: $f-body;
      color: #fff;
      border-radius: 2rem;
      height: 2.5rem;
      line-height: 2.5rem;
      padding: 0;
      width: 60%;
      font-size: 1rem;
      font-weight: 700;
      background-color: $c-red;
      // border: 2px solid $c-red-dark;
      border: none;
      box-sizing: border-box;
      cursor: pointer;
      // box-shadow: 0 2px 1px 0 rgba($c-red, .2);
      transition: .2s opacity;

      &:hover, &:focus {
        outline: none;
        background-color: $c-red-light;
      }

      &.disabled {
        opacity: 0;
        pointer-events: none;
      }
    }
    &__text {
      max-width: 500px;
      margin: 2rem auto;

      p {
        color: #888;
      }
    }
    &__video {
      width: 100%;
      height: 0;
      margin: 0 auto;
      padding-bottom: 56.25%;
      position: relative;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    &__footer {
      margin-top: 4rem;
      text-align: center;
      font-size: .8rem;
      color: #888;

      span {
        display: block;
        font-weight: 700;
        margin-bottom: .25rem;
      }
      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
          display: inline;

          a {
            text-decoration: none;
          }
          &::after {
            content: " | ";
            color: $c-red;
          }
          &:last-child::after {
            content: "";
          }
        }
      }
    }

    &__list {

      h1 {
        font-weight: normal;
        text-align: left;
        font-size: 1.5rem;
      }
    }
  }

  .c-index__content {
    @include routerTransition;
  }

</style>
