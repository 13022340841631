import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Meta from 'vue-meta'
import VueAnalytics from 'vue-analytics'

Vue.config.productionTip = false
// Vue.use(Vuex);
Vue.use(Meta);

Vue.use(VueAnalytics, {
  id: 'UA-38953662-2',
  router
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
