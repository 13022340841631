<template>
  <div class="c-mulchotaskscore">
    <span class="question">{{ taskid+1 }}. {{ task.taskable.question }}</span>
    <span v-if="!isCorrect && !isEmpty && !isSelfcheck" class="answer answer--incorrect">✖ {{ userAnswer }}</span>
    <span v-if="isSelfcheck && !isEmpty" class="answer answer--selfcheck">⁕ {{ userAnswer }}</span>
    <span v-if="isEmpty" class="answer answer--empty">—</span>
    <span class="answer answer--correct">✔ {{ correctAnswer }}</span>
  </div>
</template>

<script>
export default {
  name: 'c-inputtaskscore',
  props: ['task', 'taskid'],
  data () {
    return {
    }
  },
  watch: {

  },
  mounted() {
    console.log(this.task);
  },
  methods: {
  },
  computed: {
    sheetId() {
      return this.$store.getters.sheetId;
    },
    sheetData() {
      return this.$store.getters.sheetData;
    },
    answers() {
      return this.$store.getters.answers;
    },
    correctAnswer() {
      let t = this.task.taskable.answers;

      if(this.task.taskable.default_answer_id) {
        t = t.filter((obj) => {
          return obj.id == this.task.taskable.default_answer_id;
        });
      }

      if(t.length == 0) return false;
      return t[0].answer;
    },
    userAnswer() {
      if(!this.answers[this.task.id]) return '';
      return this.answers[this.task.id].content;
    },
    isEmpty() {
      if(!this.answers[this.task.id]) return true;
      if(this.answers[this.task.id] == '') return true;
      return false;
    },
    isSelfcheck() {
      return this.task.taskable.selfcheck;
    },
    isCorrect() {
      if(!this.answers[this.task.id]) return false;
      return this.answers[this.task.id].correct == 1;
    }
  }
}
</script>

<style lang="scss">

  @import '../../styles/helpers';

  .c-mulchotaskscore {
    margin-bottom: .5rem;
    border: 1px solid #eee;
    background-color: #fafafa;
    border-radius: 4px;
    padding: .5rem;
    
    .question {
      display: block;
      font-weight: 600;
      margin-bottom: .25rem;
    }

    .answer {
      display: block;
      padding: 5px;

      &--correct {
        background-color: #B3FDE2;
      }
      &--incorrect {
        background-color: #FFE4E4;
      }
      &--selfcheck {
        background-color: rgb(255, 251, 189);
      }
      &--empty {
        background-color: #eee;
      }
    }

  }

</style>
